import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import { withLayout } from '@/components/template/Layout'
import PlanTable from '@/components/template/Management/Plan/PlanTable'
import { COMPANY_LINKS } from '@/constant/companyLinks'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    container: {
        maxWidth: theme.contentContainer.width,
    },
    table: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'center',
    },
    totalSite: {
        marginTop: 20,
        width: 700,
        margin: '0 auto',
        display: 'flex',
    },
    siteText: {
        width: 199,
        padding: 16,
        borderRight: '3px solid #fff',
        backgroundColor: '#D8E3EA',
        fontSize: 14,
        fontWeight: 600,
    },
    siteLength: {
        width: 158,
        padding: 16,
        fontSize: 14,
        textAlign: 'center',
        borderTop: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
    },
    bubble: {
        maxWidth: 700,
        margin: '50px auto 30px',
        backgroundColor: theme.colors.lightGray,
        padding: '50px 30px',
    },
    bubbleText: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: 14,
    },
    bubbleBtn: {
        marginTop: 30,
        textAlign: 'center',
    },
    infoText: {
        textAlign: 'right',
        width: 700,
        margin: '10px auto 0',
    },
})

function PlansManagement() {
    const { storeState } = useStore()
    const classes = useStyle()

    return (
        <>
            <title>プラン情報｜ScopeX</title>
            <div className={classes.container}>
                <div className={classes.table}>
                    <PlanTable />
                </div>
                <div className={classes.infoText}>*1 簡易版実装予定</div>
                <div className={classes.totalSite}>
                    <div className={classes.siteText}>契約拠点数</div>
                    <div className={classes.siteLength}>{storeState.sites.length}</div>
                </div>
                <div className={classes.bubble}>
                    <h4 className={classes.bubbleText}>
                        プラン変更、拠点追加、オプション追加のお見積りは以下よりお進みください。
                    </h4>
                    <div className={classes.bubbleBtn}>
                        <PrimaryButton
                            width={456}
                            height={45}
                            fontSize={18}
                            onClick={() => window.open(COMPANY_LINKS.TAYORI, '_blank', 'noopener,noreferrer')}
                        >
                            見積もりを依頼する
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withLayout(PlansManagement)
